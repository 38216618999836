import { styled } from "@mui/material/styles";
import { IPresentTemplate } from "../../../const";

type Props = {
    template?: IPresentTemplate;
};

const Root = styled("div")<Props>(({ template }) => ({
    "&.checkout-wrapper": {
        textAlign: "center",
        maxWidth: "390px",
        margin: "0 auto",
        padding: "0 20px 100px",
        boxSizing: "border-box",
        "& *": {
            boxSizing: "border-box"
        },
        "& .error": {
            display: "flex",
            alignItems: "center",
            fontSize: "12px",
            color: "#E60000",
            textAlign: "left",
            margin: "6px 0 0",
            "& svg": {
                marginRight: "5px"
            }
        }
    },
    "& .form-group": {
        "+ .form-group": {
            marginTop: "20px"
        }
    },
    "& .steps-wrapper": {
        marginBottom: "30px"
    },
    "& .step-3 ": {
        "& h2": {
            marginBottom: "15px"
        }
    },
    "& h2": {
        margin: "0 auto 30px",
        fontFamily: template?.fonts?.menu_links?.family || "Inter",
        fontWeight: template?.fonts?.menu_links?.weight || "500",
        fontSize: template?.fonts?.menu_links?.size || "1.18rem",
        lineHeight: template?.fonts?.menu_links?.line_height || "2.5"
    },
    "& .helper-text": {
        fontFamily: template?.fonts?.section_links?.family || "Inter",
        fontSize: template?.fonts?.section_links?.size || "1.2rem",
        fontWeight: template?.fonts?.section_links?.weight || "500",
        lineHeight: template?.fonts?.section_links?.line_height || "1",
        marginTop: "30px",
        padding: "0 10px"
    },
    "& .helper-text-top": {
        fontFamily: template?.fonts?.section_links?.family || "Inter",
        fontSize: template?.fonts?.section_links?.size || "1.2rem",
        fontWeight: template?.fonts?.section_links?.weight || "500",
        lineHeight: template?.fonts?.section_links?.line_height || "1",
        marginBottom: "40px",
        padding: "0 10px"
    },
    "& .phone-wrapper": {
        position: "relative",
        marginTop: "25px",
        "& .form-input .MuiInputBase-input": {
            padding: "20px 20px 20px 60px"
        }
    },
    "& .phone-wrapper .prefix-code": {
        position: "absolute",
        left: "20px",
        top: "50%",
        transform: "translateY(-50%)",
        pointerEvents: "none",
        fontSize: "16px",
        fontFamily: "Roboto",
        fontWeight: 500,
        opacity: 0.4,
        zIndex: 1
    },
    "& .form-input": {
        width: "100%",
        "& + .form-input": {
            marginTop: "25px"
        },
        "& .MuiInputBase-input": {
            height: "60px",
            padding: "20px",
            textAlign: "left",
            fontSize: "16px",
            fontFamily: "Roboto",
            fontWeight: 500
        },
        "& .MuiFormLabel-root": {
            top: "4px",
            left: "6px",
            fontSize: "14px",
            fontFamily: "Roboto",
            fontWeight: 500
        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderRadius: `20px 20px 20px 20px`
            },
            "&.Mui-focused fieldset": {
                borderColor: template?.colors?.background_2 || "#222222"
            }
        },
        "& label.Mui-focused": {
            color: "#222222"
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: template?.colors?.background_2 || "#222222"
        }
    },
    "& .checkout-footer-wrapper": {
        paddingBottom: "30px",
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 1,
        position: "fixed",
        maxWidth: "390px",
        margin: "0 auto",
        boxSizing: "border-box",
        backgroundColor: "#ffffff",
        "& .btn-wrapper": {
            padding: "0 20px"
        },
        "& .primary-btn": {
            fontFamily: template?.fonts?.section_links?.family || "Inter",
            fontSize: template?.fonts?.section_links?.size || "1.2rem",
            fontWeight: template?.fonts?.section_links?.weight || "500",
            borderRadius: "10px",
            padding: "13px 15px",
            lineHeight: 1.2,
            width: "100%",
            boxShadow: "none",
            backgroundColor: template?.colors?.primary || "#CC5803",
            color: template?.fonts?.section_links?.color || "#ffffff",
            border: `2px solid ${template?.colors?.primary}`,
            textAlign: "center",
            textDecoration: "none",
            transition: "all 0.3s",
            boxSizing: "border-box",
            textTransform: "capitalize",
            "& svg": {
                "& path": {
                    transition: "all 0.3s"
                }
            },
            "&:hover": {
                backgroundColor: "transparent",
                color: template?.colors?.primary || "#CC5803",
                "& svg": {
                    "& path": {
                        fill: template?.colors?.primary || "#CC5803"
                    }
                },
                "& .MuiCircularProgress-svg": {
                    color: template?.colors?.primary || "#CC5803"
                }
            },
            "& .MuiCircularProgress-svg": {
                color: template?.fonts?.section_links?.color
            },
            "&.Mui-disabled": {
                opacity: "0.5"
            }
        }
    },
    "& .progress-bar-wrapper": {
        display: "flex",
        alignItems: "center",
        borderBottom: "1px solid #D9D9D9",
        marginBottom: "15px",
        padding: "0 20px 15px",
        "& .progress-bar": {
            borderRadius: "20px",
            width: "100%",
            backgroundColor: "#DCDCDC",
            "& .inner-bar": {
                borderRadius: "20px",
                width: "100%",
                backgroundColor: template?.colors?.primary || "#CC5803",
                transition: "all 0.3s"
            }
        },
        "& .progress-count": {
            marginLeft: "15px",
            fontSize: "18px",
            fontFamily: "Lilita One"
        }
    },
    "& .custom-select-wrapper": {
        maxHeight: "250px",
        "& .MuiMenuItem-root": {
            fontSize: "16px",
            fontFamily: "'Roboto'",
            fontWeight: 500,
            "&.Mui-selected": {
                backgroundColor: template?.colors?.primary || "#CC5803",
                color: template?.fonts?.section_links?.color || "#ffffff"
            }
        }
    },
    "& .ordered-page": {
        border: "1px solid #DDDDDD",
        borderRadius: "16px",
        padding: "30px",
        textAlign: "left",
        "& .tag": {
            margin: "0 0 0px",
            fontSize: "14px",
            fontFamily: "'Poppins'",
            fontWeight: 700,
            backgroundColor: "#000",
            color: "#ffffff",
            display: "inline-block",
            borderRadius: "8px",
            padding: "4px 12px"
        },
        "& .username": {
            fontSize: "24px",
            fontFamily: "'Lilita One'",
            marginTop: "12px"
        },
        "& .user-id": {
            fontSize: "16px",
            fontFamily: "'Lilita One'",
            marginTop: "4px"
        },
        "& .items-list": {
            listStyle: "none",
            padding: "0",
            margin: "15px 0 0",
            paddingTop: "15px",
            borderTop: "1px solid #DDDDDD",
            "& li": {
                fontFamily: "Poppins",
                fontSize: "15px",
                "+ li": {
                    marginTop: "10px"
                }
            }
        }
    }
}));
export { Root };
