import { Typography } from "@mui/material";
import { useGlobalContext } from "../../../../context/Global";
import { FormattedMessage } from "react-intl";
import { STELLAR_MENUS_WEBSITE } from "../../../../const";

export default function Footer3(props: { footerMessage?: string }) {
    const { footerMessage } = props;
    const { fullMenu } = useGlobalContext();

    const primaryBackgroundColor =
        fullMenu?.presenter_template?.colors?.primary;
    return (
        <footer
            style={{
                padding: "8px 16px 16px",
                background: `${
                    primaryBackgroundColor ? primaryBackgroundColor : `#ffffff`
                }`,
                width: "100%"
            }}
        >
            <Typography
                sx={{
                    fontFamily:
                        fullMenu?.presenter_template.fonts?.menu_footer
                            ?.family || "Roboto",
                    fontSize:
                        fullMenu?.presenter_template?.fonts?.menu_footer
                            ?.size || "1rem",
                    fontWeight:
                        fullMenu?.presenter_template?.fonts?.menu_footer
                            ?.weight || "1.62rem",
                    fontStyle:
                        fullMenu?.presenter_template?.fonts?.menu_footer?.style,
                    lineHeight:
                        fullMenu?.presenter_template?.fonts?.menu_footer
                            ?.line_height,
                    color: fullMenu?.presenter_template?.fonts?.menu_footer
                        ?.color,
                    pt: "25px",
                    pl: "28px",
                    pr: "28px",
                    pb: "172px",
                    position: "relative",
                    whiteSpace: "pre-line"
                }}
            >
                {footerMessage}
                <span
                    style={{
                        display: "table",
                        margin: "0 auto",
                        marginTop: "50px",
                        fontFamily: "Roboto",
                        fontSize: "12px"
                    }}
                >
                    <span>
                        <a
                            href={STELLAR_MENUS_WEBSITE}
                            target="_blank"
                            style={{
                                textDecoration: "none"
                            }}
                            rel="noreferrer"
                        >
                            <FormattedMessage id="powered_by_sm" />
                            <span
                                style={{
                                    display: "table",
                                    margin: "0 auto"
                                }}
                            >
                                milescx.com
                            </span>
                        </a>
                    </span>
                </span>
            </Typography>
        </footer>
    );
}
