import { styled } from "@mui/material/styles";
import { IPresentTemplate } from "../../../const";

type Props = {
    template?: IPresentTemplate;
};

const Root = styled("div")<Props>(({ template }) => ({
    "&.btnWrapper": {
        display: "flex",
        justifyContent: "space-between",
        marginTop: "20px",
        padding: "24px 32px",
        borderTop: "1px solid #D9D9D9",
        boxShadow: "0px 0px 16px 0px #0000000D",
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 1,
        backgroundColor: "#fff",
        position: "fixed",
        maxWidth: "500px",
        margin: "0 auto",
        boxSizing: "border-box",
        "& .cartBtn": {
            fontFamily: template?.fonts?.section_links?.family || "Inter",
            fontSize: template?.fonts?.section_links?.size || "1.2rem",
            fontWeight: template?.fonts?.section_links?.weight || "500",
            borderRadius: "10px",
            padding: "10px",
            lineHeight: 1.2,
            width: "47%",
            boxShadow: "none",
            backgroundColor: template?.colors?.primary || "#CC5803",
            color: template?.fonts?.section_links?.color || "#fff",
            border: `2px solid ${template?.colors?.primary}`,
            textAlign: "center",
            textDecoration: "none",
            transition: "all 0.3s",
            boxSizing: "border-box",
            textTransform: "capitalize",
            "&:hover": {
                backgroundColor: "transparent",
                color: template?.colors?.primary || "#CC5803",
                "& .MuiCircularProgress-svg": {
                    color: template?.colors?.primary || "#CC5803"
                }
            },
            "&.clearCartBtn": {
                backgroundColor: "transparent",
                border: "2px solid #888",
                color: "#888",
                textTransform: "capitalize",
                "&:hover": {
                    backgroundColor: "#888",
                    color: "#fff"
                }
            },
            "& .MuiCircularProgress-svg": {
                color: template?.fonts?.section_links?.color
            },
            "&.Mui-disabled": {
                opacity: "0.5"
            }
        }
    },
    "& .backToMenu": {
        position: "absolute"
    }
}));

export { Root };
