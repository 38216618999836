/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import Button from "@mui/material/Button";
import { useNavigate, useParams } from "react-router-dom";
import { Container, Grid } from "@mui/material";
import { useGlobalContext } from "../../../../context/Global";
import { IPresentTemplate } from "../../../../const";

const Navbar1 = (props: {
    sendTo?: "web" | "presenter";
    template: Partial<IPresentTemplate>;
}) => {
    const { sendTo = "presenter", template } = props;
    const { fullMenu, collection, search } = useGlobalContext();
    const navigate = useNavigate();
    const { menu: menuId } = useParams();

    function isSelected(id: string) {
        return id === menuId;
    }

    const getFontWeight = (isSelected: boolean): string | number => {
        const menuLinkWeight = template?.fonts?.menu_links?.weight || "500";

        return !search && !isSearch && isSelected
            ? parseInt(menuLinkWeight) + 100
            : menuLinkWeight;
    };

    const urlSearchParams = new URLSearchParams(window.location.search);
    const isOrderOn = urlSearchParams.get("order") === "on";
    const isSearch = urlSearchParams.get("s");

    const Items = () => (
        <div className="navbar-container">
            {collection?.collection_id?.menu_links.map((item) => (
                <Button
                    key={item.menu_id._id}
                    onClick={() => {
                        navigate(
                            `${sendTo === "web" ? "/web" : ""}/${
                                fullMenu?.identifier
                            }/${collection?.collection_id?._id}/${
                                item.menu_id._id
                            }${isOrderOn ? "?order=on" : ""}`
                        );
                    }}
                    style={{
                        color: template?.fonts?.menu_links?.color || "black",
                        fontFamily:
                            template?.fonts?.menu_links?.family || "Inter",
                        fontWeight: getFontWeight(isSelected(item.menu_id._id)),
                        fontSize:
                            template?.fonts?.menu_links?.size || "1.18rem",
                        lineHeight:
                            template?.fonts?.menu_links?.line_height || "2.5",
                        textTransform: "unset",
                        padding: "0 20px 0 20px",
                        marginTop: "0",
                        marginBottom: "0",
                        whiteSpace: "nowrap",
                        flex: "0 0 auto",
                        margin: "auto",
                        borderBottom: `3px solid ${
                            !search && !isSearch && isSelected(item.menu_id._id)
                                ? template?.colors?.primary || "#CC5803"
                                : template?.colors?.tertiary || "#f2cfb6"
                        }`,
                        borderRadius: "0"
                    }}
                >
                    {item.menu_id.display_name}
                </Button>
            ))}
        </div>
    );

    return (
        <Grid item xs={12}>
            {props.sendTo === "web" ? (
                <Container maxWidth="md">
                    <Items></Items>
                </Container>
            ) : (
                <Items></Items>
            )}
        </Grid>
    );
};
export default Navbar1;
