export function showPrice(
    priceObj: {
        price?: string | undefined;
        price_value?: number | undefined;
        leading_text?: string | undefined;
        trailing_text?: string | undefined;
    },
    configs: {
        currency_symbol?: string;
        currency_placement?: "before" | "after";
        show_item_price?: boolean;
        show_empty_decimals?: boolean;
        show_currency_symbol?: boolean;
    }
) {
    const {
        currency_symbol = "$",
        currency_placement = "before",
        show_item_price = true,
        show_empty_decimals = false,
        show_currency_symbol = true
    } = configs;
    if (!show_item_price) return "";

    /* if (currency_placement === "before") {
        return `${
            Boolean(priceObj.leading_text) ? priceObj.leading_text + " " : ""
        }${show_currency_symbol ? currency_symbol : ""}${
            show_empty_decimals
                ? formatPriceWithDecimals(priceObj.price_value)
                : Boolean(priceObj.price_value)
                ? priceObj.price_value
                : ""
        } ${Boolean(priceObj.trailing_text) ? priceObj.trailing_text : ""}`;
    }
    return `${
        Boolean(priceObj.leading_text) ? priceObj.leading_text + " " : ""
    }${
        show_empty_decimals
            ? formatPriceWithDecimals(priceObj.price_value)
            : priceObj.price_value
    } ${show_currency_symbol ? currency_symbol : ""} ${
        Boolean(priceObj.trailing_text) ? priceObj.trailing_text : ""
    }`;*/
    const { price_value, leading_text, trailing_text } = priceObj;
    const currencySymbol = show_currency_symbol ? currency_symbol : "";
    const price = show_empty_decimals
        ? formatPriceWithDecimals(price_value)
        : price_value;

    if (currency_placement === "before") {
        return `${Boolean(leading_text) ? leading_text + " " : ""}${
            Boolean(price) ? currencySymbol : ""
        }${Boolean(price) ? price : ""}${
            Boolean(trailing_text) ? " " + trailing_text : ""
        }`.trim();
    }
    return `${Boolean(leading_text) ? leading_text + " " : ""}${
        Boolean(price) ? price : ""
    } ${Boolean(price) ? currencySymbol + " " : ""}${
        Boolean(trailing_text) ? trailing_text : ""
    }`.trim();
}

// If the price is 10, it should return 10.00
function formatPriceWithDecimals(price?: number) {
    if (!price) return "";
    return price.toFixed(2);
}

export function showPriceNumber(
    priceObj: {
        price?: string | undefined;
        price_value?: number | undefined;
    },
    configs: {
        show_item_price?: boolean;
    }
) {
    const { show_item_price = true } = configs;
    if (!show_item_price) return null;

    return priceObj.price_value || 0;
}
