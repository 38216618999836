import { styled } from "@mui/material/styles";

const classes = {
    logoContainer: `logoContainer`,
    logo: `logo`,
    container: `container`,
    title: `title`
};

const Root = styled("div")(({ theme }) => ({
    [`& .${classes.logoContainer}`]: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    [`& .${classes.logo}`]: { height: "70px", margin: "20px 0 20px 20px" },
    [`& .${classes.container}`]: {
        justifyContent: "center"
    },
    [`& .${classes.title}`]: {
        fontWeight: 200,
        fontSize: "20px",
        textAlign: "center",
        marginBottom: "40px",
        fontFamily: "Poppins"
    }
}));
export { Root, classes };
