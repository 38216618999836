import React from "react";
import { IPresentTemplate, STELLAR_MENUS_WEBSITE } from "../../../../const";
import { Root, classes } from "./styles";
import { FormattedMessage } from "react-intl";

export default function FooterOne(props: {
    footerMessage?: string;
    template: Partial<IPresentTemplate>;
}) {
    const { template } = props;

    const { footerMessage } = props;

    return (
        <Root template={template} className={classes.footer}>
            <div className={classes.footerContainer}>
                {footerMessage && (
                    <p className={classes.footerCustomMessage}>
                        {footerMessage}
                    </p>
                )}
                <a
                    href={STELLAR_MENUS_WEBSITE}
                    target="_blank"
                    style={{
                        textDecoration: "none"
                    }}
                    rel="noreferrer"
                >
                    <p className={classes.footerMessage}>
                        <FormattedMessage id="powered_by_sm" />
                    </p>
                    <p className={classes.footerMessage2}>milescx.com</p>
                </a>
            </div>
        </Root>
    );
}
