import {
    BrowserRouter as Router,
    Routes,
    Route,
    Navigate,
    useLocation,
    useParams
} from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import ListingPage from "../pages/Listing";
import { getTemplatePages, IPages } from "../const/presenterTemplates";
import { GlobalContextProvider, useGlobalContext } from "../context/Global";
import { TrackContextProvider } from "../context/Tracking";
import SetRestaurantInformation from "../SharedComponents/SetRestaurantInformation";
import Track from "../SharedComponents/Track";
import FontImporter from "../FontImporter";
import SetCookies from "../SharedComponents/SetCookies";
import { getWebTemplatePages, IWebPages } from "../const/webTemplates";
import { IntlProvider } from "react-intl";
import Spanish from "../locales/es/translation.json";
import English from "../locales/en/translation.json";
import { useEffect, useState } from "react";
import { HEALTH_CHECK, LISTING_PAGE_URI, NOT_FOUND_PAGE_URI } from "../const";
import NotFound from "../pages/NotFound";
import {
    addItemToSessionStorage,
    getItemFromSessionStorage,
    removeItemFromSessionStorage
} from "../utils/sesstionStorage";
import HealthCheck from "../pages/HealthCheck";

const messages = {
    es: Spanish,
    en: English
};

const flattenMessages = (nestedMessages: any, prefix = "") => {
    if (nestedMessages === null) {
        return {};
    }
    return Object.keys(nestedMessages).reduce((messages, key) => {
        const value = nestedMessages[key];
        const prefixedKey = prefix ? `${prefix}.${key}` : key;

        if (typeof value === "string") {
            Object.assign(messages, { [prefixedKey]: value });
        } else {
            Object.assign(messages, flattenMessages(value, prefixedKey));
        }

        return messages;
    }, {});
};

function TemplateChoose(props: { page: keyof IPages }) {
    const { page } = props;

    const { fullMenu } = useGlobalContext();

    return getTemplatePages(fullMenu?.presenter_template.template_id, {})[
        page
    ]();
}

function SetHead() {
    const { fullMenu } = useGlobalContext();
    const [favIcon, setFavIcon] = useState("");
    const [largeImage, setLargeImage] = useState("");
    const [dynamicTitle, setDynamicTitle] = useState("");
    const [metaDesc, setMetaDesc] = useState("");

    const { restaurant: restaurantIdentifier } = useParams();

    useEffect(() => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const isDemoOn = urlSearchParams.get("demo") === "on";
        const isOrderOn = urlSearchParams.get("order") === "on";
        const isWhatsapp = urlSearchParams.get("app") === "wap";
        const isSMS = urlSearchParams.get("app") === "sms";
        const isSession = urlSearchParams.get("session");
        const isLang = urlSearchParams.get("lang");
        const isLoc = urlSearchParams.get("loc");

        const getMode = (key: any) => {
            if (
                (isDemoOn && key === "demoMode") ||
                (isOrderOn && key === "orderMode") ||
                (isWhatsapp && key === "whatsAppMode") ||
                (isSMS && key === "textMode") ||
                (isSession && key === "sessionMode") ||
                (isLang && key === "langMode") ||
                (isLoc && key === "locMode")
            ) {
                const mode = getItemFromSessionStorage(key);
                if (!mode) {
                    if (key === "sessionMode") {
                        addItemToSessionStorage(key, `${isSession}`);
                        return getItemFromSessionStorage(key);
                    } else if (key === "langMode") {
                        addItemToSessionStorage(key, `${isLang}`);
                        return getItemFromSessionStorage(key);
                    } else if (key === "locMode") {
                        addItemToSessionStorage(key, `${isLoc}`);
                        return getItemFromSessionStorage(key);
                    }
                    addItemToSessionStorage(key, `${restaurantIdentifier}-on`);
                    return getItemFromSessionStorage(key);
                }
                return mode;
            }
            return undefined;
        };

        const demoMode = getMode("demoMode");
        const orderMode = getMode("orderMode");
        getMode("whatsAppMode");
        getMode("textMode");
        getMode("sessionMode");
        getMode("langMode");
        getMode("locMode");

        const isDesktop = window.innerWidth >= 1024;
        if (demoMode === `${restaurantIdentifier}-on` && isDesktop) {
            document.body.classList.add("mobile-version");
        }

        if (orderMode === `${restaurantIdentifier}-on`) {
            document.body.classList.add("order-version");
        }

        return () => {
            document.body.classList.remove("mobile-version", "order-version");
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const handleBeforeUnload = () => {
            removeItemFromSessionStorage("demoMode");
            removeItemFromSessionStorage("orderMode");
            removeItemFromSessionStorage("whatsAppMode");
            removeItemFromSessionStorage("textMode");
            removeItemFromSessionStorage("sessionMode");
            removeItemFromSessionStorage("langMode");
            removeItemFromSessionStorage("locMode");
        };

        // Attach the event listener
        window.addEventListener("beforeunload", handleBeforeUnload);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
        };
    }, []);

    const location = useLocation();
    useEffect(() => {
        const updateUrlParam = (paramName: any, paramValue: any) => {
            if (!location.search.includes(`${paramName}=${paramValue}`)) {
                const newUrl =
                    window.location.href +
                    (window.location.search ? "&" : "?") +
                    `${paramName}=${paramValue}`;
                window.history.replaceState({}, "", newUrl);
            }
        };

        const demoMode = getItemFromSessionStorage("demoMode");
        const orderMode = getItemFromSessionStorage("orderMode");
        const whatsAppMode = getItemFromSessionStorage("whatsAppMode");
        const textMode = getItemFromSessionStorage("textMode");
        const sessionMode = getItemFromSessionStorage("sessionMode");
        const langMode = getItemFromSessionStorage("langMode");
        const locMode = getItemFromSessionStorage("locMode");

        if (demoMode === `${restaurantIdentifier}-on`) {
            updateUrlParam("demo", "on");
        }

        if (orderMode === `${restaurantIdentifier}-on`) {
            updateUrlParam("order", "on");
        }

        if (whatsAppMode === `${restaurantIdentifier}-on`) {
            updateUrlParam("app", "wap");
        }

        if (textMode === `${restaurantIdentifier}-on`) {
            updateUrlParam("app", "sms");
        }

        if (sessionMode) {
            updateUrlParam("session", `${sessionMode}`);
        }

        if (langMode) {
            updateUrlParam("lang", `${langMode}`);
        }

        if (locMode) {
            updateUrlParam("loc", `${locMode}`);
        }

        // Check if the current pathname includes "/web"
        if (location.pathname.includes("/web")) {
            // If it does, add a class to the body
            document.body.classList.add("web-template");
        } else {
            document.body.classList.remove("web-template");
        }

        // Cleanup: remove the class when the component unmounts
        return () => {
            document.body.classList.remove("web-template");
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]); // This effect runs whenever the route changes

    // Handles browser back button event to reload the page when navigating back to "/listing" URL.
    useEffect(() => {
        window.addEventListener("popstate", () => {
            if (window.location.pathname === "/listing") {
                window.location.reload();
            }
        });
    }, []);

    useEffect(() => {
        const restaurant_title = fullMenu?.display_name || "MilesCX";
        const meta_description =
            fullMenu?.presenter_template?.controls?.meta_description ||
            "Explore our enticing restaurant menu with a variety of delicious dishes. Join us for an unforgettable dining experience!";
        const favicon_image =
            fullMenu?.presenter_template?.images?.restaurant_favicon_image ||
            "/stellar192.png";
        const large_image =
            fullMenu?.presenter_template?.images?.restaurant_meta_image ||
            fullMenu?.logo_image ||
            "https://stellarmenus.com/wp-content/uploads/2022/04/stellar-menus-for-white-bg-768x226.png";
        setDynamicTitle(restaurant_title);
        setMetaDesc(meta_description);
        setFavIcon(favicon_image);
        setLargeImage(large_image);
    }, [
        fullMenu?.logo_image,
        fullMenu?.display_name,
        fullMenu?.presenter_template?.images?.restaurant_favicon_image,
        fullMenu?.presenter_template?.controls?.meta_description,
        fullMenu?.presenter_template?.images?.restaurant_meta_image
    ]);

    return (
        <>
            <Helmet>
                <title>{dynamicTitle}</title>
                <link
                    rel="icon"
                    type="image/x-icon"
                    sizes="16x16"
                    href={favIcon}
                />
                <link rel="apple-touch-icon" sizes="16x16" href={favIcon} />
                {/* Add Open Graph meta tags */}
                <meta property="og:title" content={dynamicTitle} />
                <meta property="og:description" content={metaDesc} />
                <meta property="og:image" content={largeImage} />
                {/* <meta property="og:url" content="https://www.yourappurl.com" /> */}
                <meta property="og:type" content="website" />
                {/* Add Twitter Card meta tags */}
                <meta name="twitter:title" content={dynamicTitle} />
                <meta name="twitter:description" content={metaDesc} />
                <meta name="twitter:image" content={largeImage} />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="description" content={metaDesc} />
            </Helmet>
        </>
    );
}

function ChooseWebTemplate(props: { page: keyof IWebPages }) {
    const { page } = props;

    const { fullMenu } = useGlobalContext();

    return getWebTemplatePages(fullMenu?.presenter_template.template_id, {})[
        page
    ]();
}

export default function Routing() {
    const [config, setConfig] = useState({
        locale: "en",
        messages: flattenMessages(English)
    });

    function onChangeLanguage(lang: string) {
        if (lang !== null) {
            setConfig({
                locale: lang,
                messages: flattenMessages(
                    messages[lang as keyof typeof messages] || English
                )
            });
        }
    }

    const urlSearchParams = new URLSearchParams(window.location.search);
    const isOrderOn = urlSearchParams.get("order") === "on";

    return (
        <Router>
            <HelmetProvider>
                <GlobalContextProvider onChangeLanguage={onChangeLanguage}>
                    <IntlProvider
                        locale={config.locale}
                        messages={config.messages}
                    >
                        <TrackContextProvider>
                            <Routes>
                                <Route
                                    path={LISTING_PAGE_URI}
                                    element={
                                        <>
                                            <SetHead></SetHead>
                                            <ListingPage />
                                        </>
                                    }
                                />
                                <Route
                                    path="/web/:restaurant/:schedule/:menu"
                                    element={
                                        <SetRestaurantInformation>
                                            <SetHead></SetHead>
                                            <FontImporter></FontImporter>
                                            <ChooseWebTemplate page="menuPage"></ChooseWebTemplate>
                                        </SetRestaurantInformation>
                                    }
                                ></Route>
                                <Route
                                    path="/web/:restaurant/:schedule"
                                    element={
                                        <SetRestaurantInformation>
                                            <SetHead></SetHead>
                                            <FontImporter></FontImporter>
                                            <ChooseWebTemplate page="collectionPage"></ChooseWebTemplate>
                                        </SetRestaurantInformation>
                                    }
                                ></Route>
                                <Route
                                    path="/:restaurant/:schedule/:menu"
                                    element={
                                        <Track>
                                            <SetRestaurantInformation>
                                                <SetHead></SetHead>
                                                <SetCookies>
                                                    <FontImporter></FontImporter>
                                                    <TemplateChoose page="menuPage"></TemplateChoose>
                                                </SetCookies>
                                            </SetRestaurantInformation>
                                        </Track>
                                    }
                                ></Route>
                                <Route
                                    path="/:restaurant/:schedule"
                                    element={
                                        <Track>
                                            <SetRestaurantInformation>
                                                <SetHead></SetHead>
                                                <SetCookies>
                                                    <FontImporter></FontImporter>
                                                    <TemplateChoose page="schedulePage"></TemplateChoose>
                                                </SetCookies>
                                            </SetRestaurantInformation>
                                        </Track>
                                    }
                                ></Route>
                                <Route
                                    path="/:restaurant/favorites"
                                    element={
                                        <Track>
                                            <SetRestaurantInformation>
                                                <SetHead></SetHead>
                                                <SetCookies>
                                                    <FontImporter></FontImporter>
                                                    <TemplateChoose page="favoritePage"></TemplateChoose>
                                                </SetCookies>
                                            </SetRestaurantInformation>
                                        </Track>
                                    }
                                ></Route>
                                <Route
                                    path="/:restaurant/cart"
                                    element={
                                        <Track>
                                            <SetRestaurantInformation>
                                                <SetHead></SetHead>
                                                <SetCookies>
                                                    <FontImporter></FontImporter>
                                                    {isOrderOn ? (
                                                        <TemplateChoose page="cartPage" />
                                                    ) : (
                                                        <NotFound />
                                                    )}
                                                </SetCookies>
                                            </SetRestaurantInformation>
                                        </Track>
                                    }
                                ></Route>
                                <Route
                                    path="/:restaurant/checkout"
                                    element={
                                        <Track>
                                            <SetRestaurantInformation>
                                                <SetHead></SetHead>
                                                <SetCookies>
                                                    <FontImporter></FontImporter>
                                                    {isOrderOn ? (
                                                        <TemplateChoose page="checkoutPage" />
                                                    ) : (
                                                        <NotFound />
                                                    )}
                                                </SetCookies>
                                            </SetRestaurantInformation>
                                        </Track>
                                    }
                                ></Route>
                                <Route
                                    path="/:restaurant"
                                    element={
                                        <Track>
                                            <SetRestaurantInformation>
                                                <SetHead></SetHead>
                                                <SetCookies>
                                                    <FontImporter></FontImporter>
                                                    <TemplateChoose page="restaurantPage"></TemplateChoose>
                                                </SetCookies>
                                            </SetRestaurantInformation>
                                        </Track>
                                    }
                                ></Route>
                                <Route
                                    path={NOT_FOUND_PAGE_URI}
                                    element={
                                        <>
                                            <SetHead></SetHead>
                                            <NotFound />
                                        </>
                                    }
                                />
                                <Route
                                    path={HEALTH_CHECK}
                                    element={
                                        <>
                                            <SetHead></SetHead>
                                            <HealthCheck />
                                        </>
                                    }
                                />
                                <Route
                                    path="/"
                                    element={
                                        <>
                                            <SetHead></SetHead>
                                            <Navigate
                                                to={LISTING_PAGE_URI}
                                                replace
                                            ></Navigate>
                                        </>
                                    }
                                ></Route>
                                <Route
                                    path="*"
                                    element={
                                        <>
                                            <SetHead></SetHead>
                                            <Navigate
                                                to={NOT_FOUND_PAGE_URI}
                                                replace
                                            ></Navigate>
                                        </>
                                    }
                                ></Route>
                            </Routes>
                        </TrackContextProvider>
                    </IntlProvider>
                </GlobalContextProvider>
            </HelmetProvider>
        </Router>
    );
}
