const handleLocalStorage = () => {
    const getItem = (key: string) => {
        const value = localStorage.getItem(key);
        return value ? JSON.parse(value) : null; // Parse the JSON value on retrieval
    };

    const setItem = (key: string, value: any) => {
        localStorage.setItem(key, JSON.stringify(value)); // Convert value to string before storing
    };

    const removeItem = (key: string) => localStorage.removeItem(key);

    const clearStorage = () => localStorage.clear();

    return {
        getItem,
        setItem,
        removeItem,
        clearStorage
    };
};

export default handleLocalStorage;
