/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import {
    ICollections,
    IFullMenu,
    IGlobalContext,
    IItem,
    IMenu
} from "../const";

const GlobalContext = React.createContext<Partial<IGlobalContext>>({});
GlobalContext.displayName = "GlobalContext";

function GlobalContextProvider(props: any) {
    const [fullMenu, setFullMenu] = React.useState<Partial<IFullMenu>>({});
    const [collection, setCollection] = React.useState<Partial<ICollections>>(
        {}
    );
    const [menu, setMenu] = React.useState<Partial<IMenu>>({});
    const [allFavorites, setAllFavorites] = React.useState<Array<string>>([]);
    const [cartData, setCartData] = React.useState<Array<object>>([]);
    const [search, setSearch] = React.useState<string>("");
    const [filteredItems, setFilteredItems] = React.useState<Array<IItem>>([]);
    const [showNotations, setShowNotations] = React.useState<boolean>(false);
    const [language, setLanguage] = React.useState<string>("");
    const [lastCheck, setLastCheck] = React.useState<number>(Date.now());
    const [checkoutStep, setCheckoutStep] = React.useState<number>(1);
    const [checkoutResponse, setCheckoutResponse] = React.useState(null);
    const [formData, setFormData] = React.useState({
        location: "",
        name: "",
        memberId: "",
        countryCode: 1,
        phoneNumber: ""
    });

    const value = React.useMemo(
        () => ({
            formData,
            setFormData,
            fullMenu,
            setFullMenu,
            collection,
            setCollection,
            menu,
            setMenu,
            allFavorites,
            setAllFavorites,
            cartData,
            setCartData,
            search,
            setSearch,
            filteredItems,
            setFilteredItems,
            showNotations,
            setShowNotations,
            language,
            setLanguage,
            lastCheck,
            setLastCheck,
            onChangeLanguage: props.onChangeLanguage,
            setCheckoutStep,
            checkoutStep,
            checkoutResponse,
            setCheckoutResponse
        }),
        [
            formData,
            setFormData,
            fullMenu,
            setFullMenu,
            collection,
            setCollection,
            menu,
            setMenu,
            cartData,
            setCartData,
            allFavorites,
            setAllFavorites,
            search,
            setSearch,
            filteredItems,
            setFilteredItems,
            showNotations,
            setShowNotations,
            language,
            setLanguage,
            lastCheck,
            setLastCheck,
            props.onChangeLanguage,
            setCheckoutStep,
            checkoutStep,
            checkoutResponse,
            setCheckoutResponse
        ]
    );

    return <GlobalContext.Provider value={value} {...props} />;
}

/**
 * This is a hook that allows access to the global context object.
 */
function useGlobalContext() {
    const context = React.useContext(GlobalContext) as IGlobalContext;
    if (!context) {
        throw new Error(
            "GlobalContext must be used within a GlobalContextProvider"
        );
    }
    return context;
}

export { GlobalContext, GlobalContextProvider, useGlobalContext };
