import { Root, classes } from "./styles";
import { FormattedMessage } from "react-intl";

export default function FooterListing(props: { footerMessage?: string }) {
    const { footerMessage } = props;

    return (
        <Root className={classes.footer}>
            <div className={classes.footerContainer}>
                {footerMessage && (
                    <p className={classes.footerCustomMessage}>
                        {footerMessage}
                    </p>
                )}
                <p className={classes.footerMessage}>
                    <FormattedMessage id="powered_by_sm" />
                </p>
                <p className={classes.footerMessage2}>milescx.com</p>
            </div>
        </Root>
    );
}
