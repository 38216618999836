import React from "react";
import { Navigate } from "react-router-dom";
import { DEFAULT_TEMPLATE_IDENTIFIER } from ".";
import { Collection998 } from "../PresenterTemplates/Template998/Collection";
import Menu998 from "../PresenterTemplates/Template998/Menu";
import Restaurant998 from "../PresenterTemplates/Template998/Restaurant";
import { Collection2 } from "../PresenterTemplates/Template2/Collection";
import Favorite2 from "../PresenterTemplates/Template2/Favorite";
import Menu2 from "../PresenterTemplates/Template2/Menu";
import Restaurant2 from "../PresenterTemplates/Template2/Restaurant";
import { Collection1 } from "../PresenterTemplates/Template1/Collection";
import Favorite1 from "../PresenterTemplates/Template1/Favorite";
import Menu1 from "../PresenterTemplates/Template1/Menu";
import Restaurant1 from "../PresenterTemplates/Template1/Restaurant";
import Cart1 from "../PresenterTemplates/Template1/Cart";
import CheckoutPage from "../PresenterTemplates/Template1/Checkout";

export interface IPages {
    restaurantPage: () => React.ReactElement;
    schedulePage: () => React.ReactElement;
    menuPage: () => React.ReactElement;
    favoritePage: () => React.ReactElement;
    cartPage: () => React.ReactElement;
    checkoutPage: () => React.ReactElement;
}
interface ITemplates {
    "1": IPages;
    "2": IPages;
    "998": IPages;
}

interface ITemplateProps {}

export const getTemplatePages = (id: any, props: ITemplateProps) => {
    const _id = id as keyof ITemplates;
    const templates: ITemplates = {
        "1": {
            restaurantPage: () => <Restaurant1></Restaurant1>,
            schedulePage: () => <Collection1></Collection1>,
            menuPage: () => <Menu1></Menu1>,
            favoritePage: () => <Favorite1></Favorite1>,
            cartPage: () => <Cart1></Cart1>,
            checkoutPage: () => <CheckoutPage></CheckoutPage>
        },
        "2": {
            restaurantPage: () => <Restaurant2></Restaurant2>,
            schedulePage: () => <Collection2></Collection2>,
            menuPage: () => <Menu2></Menu2>,
            favoritePage: () => <Favorite2></Favorite2>,
            cartPage: () => <Cart1></Cart1>,
            checkoutPage: () => <CheckoutPage></CheckoutPage>
        },
        "998": {
            restaurantPage: () => <Restaurant998 {...props}></Restaurant998>,
            schedulePage: () => <Collection998 {...props}></Collection998>,
            menuPage: () => <Menu998 {...props}></Menu998>,
            favoritePage: () => <Navigate to="/"></Navigate>,
            cartPage: () => <Navigate to="/"></Navigate>,
            checkoutPage: () => <CheckoutPage></CheckoutPage>
        }
    };
    return id in templates
        ? templates[_id]
        : templates[DEFAULT_TEMPLATE_IDENTIFIER];
};
