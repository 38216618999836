import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import createPalette from "@mui/material/styles/createPalette";

// The default material UI theme, in a future we could se dynamic values here instad of hardcoding the values.
let mainTheme = createTheme({
    palette: createPalette({
        primary: {
            main: "#CC5803",
            contrastText: "white"
        },
        secondary: {
            main: "#087E8B",
            contrastText: "white"
        }
    }),
    typography: {
        fontFamily: [
            "Montserrat",
            "Poppins",
            "sans-serif",
            "Inter",
            "Lilita One",
            "Roboto Mono",
            "Roboto"
        ].join(","),
        fontSize: 12,
        button: {
            fontWeight: 900
        }
    }
});

mainTheme = responsiveFontSizes(mainTheme);

export default mainTheme;
