export const DEFAULT_TEMPLATE_IDENTIFIER = 1;

export interface IGlobalContext {
    fullMenu?: IFullMenu;
    setFullMenu: Function;
    collection: { collection_id: ICollections };
    setCollection: Function;
    menu: { menu_id: IMenu };
    setMenu: Function;
    allFavorites: Array<string>;
    setAllFavorites: Function;
    cartData: Array<string>;
    setCartData: Function;
    search: string;
    setSearch: Function;
    filteredItems: Array<IItem>;
    setFilteredItems: Function;
    showNotations: boolean;
    setShowNotations: React.Dispatch<React.SetStateAction<boolean>>;
    language: string;
    setLanguage: React.Dispatch<React.SetStateAction<string>>;
    lastCheck: number;
    setLastCheck: React.Dispatch<React.SetStateAction<number>>;
    onChangeLanguage: Function;
    checkoutStep: number;
    setCheckoutStep: React.Dispatch<React.SetStateAction<number>>;
    checkoutResponse: object | null;
    setCheckoutResponse: React.Dispatch<React.SetStateAction<any>>;
    formData: any | null;
    setFormData: React.Dispatch<React.SetStateAction<any>>;
}
interface ControlProps {
    // Template 1
    show_logo?: "bottom" | "top";
    show_item_separator: boolean;
    accordion_section?: boolean;
    item_counter?: boolean;
    show_back_to_top?: boolean;
    accordion_section_starts_open: boolean;
    show_splash_page: boolean;
    show_social_media: boolean;
    show_item_images: boolean;
    show_item_price: boolean;
    show_empty_decimals: boolean;
    show_currency_symbol: boolean;
    show_service_period_name: boolean;
    show_read_more_less: boolean;
    meta_description: string;
}

interface ColorProps {
    primary: string;
    secondary: string;
    tertiary: string;
    background_1: string;
    text: string;
    background_2: string;
}

interface ImageProps {
    header_image: string;
    featured_image: string;
    restaurant_background_image?: string;
    restaurant_header_image?: string;
    restaurant_favicon_image?: string;
    restaurant_meta_image?: string;
    section_background_image?: string;
    item_image_border_radius?: string;
}

export interface RestaurantFontProp {
    name?: string;
    family?: string;
    url?: string;
    weight?: string;
    size?: string;
    style?: string;
    line_height?: string;
    color?: string;
    background?: string;
    border_width?: string;
    border_color?: string;
    border_radius?: string;
    padding?: string;
    button_width?: string;
    button_box_shadow?: string;
}

export interface FrontProps {
    language_selector?: RestaurantFontProp;
    restaurant_message?: RestaurantFontProp;
    restaurant_button?: RestaurantFontProp;
    restaurant_button_2?: RestaurantFontProp;
    restaurant_button_3?: RestaurantFontProp;
    collection_name?: RestaurantFontProp;
    collection_description?: RestaurantFontProp;
    restaurant?: RestaurantFontProp;
    collection: RestaurantFontProp;
    menu_links?: RestaurantFontProp;
    menu_header?: RestaurantFontProp;
    section_links?: RestaurantFontProp;
    section?: RestaurantFontProp;
    item_name?: RestaurantFontProp;
    item_description?: RestaurantFontProp;
    item_price?: RestaurantFontProp;
    notations_slider?: RestaurantFontProp;
    notations?: RestaurantFontProp;
    search_text?: RestaurantFontProp;
    section_header?: RestaurantFontProp;
    menu_footer?: RestaurantFontProp;
    footer?: RestaurantFontProp;
}

interface SocialMediaProp {
    show_social_media: boolean;
    color: string;
    background: string;
}
interface SocialMediaProps {
    show_social_media: boolean;
    facebook?: SocialMediaProp;
    instagram?: SocialMediaProp;
    twitter?: SocialMediaProp;
    pinterest?: SocialMediaProp;
    yelp?: SocialMediaProp;
    tiktok?: SocialMediaProp;
}

interface LanguageSelectorProps {
    background_color?: string;
    text_color?: string;
    drawer_text_color?: string;
    border_color?: string;
    radio_color?: string;
}

interface ItemStylesProps {
    item_button?: {
        background_color: string;
        border_color: string;
        text_color: string;
        border_width: string;
        border_radius: string;
    };
}

export interface IPresentTemplate {
    template_id: string;
    // Background color of the navbar
    navbar_color: string;
    // Color of the navbar items
    navbar_text_color: string;
    // css style for section border
    border?: string;
    // show restaurant image in restaurant and collecion pages
    showRestaurantImage?: boolean;
    // replace section titles with logo
    showSectionImage?: boolean;
    // Tempate 2 config
    controls?: ControlProps;
    colors?: ColorProps;
    images?: ImageProps;
    fonts?: FrontProps;
    item_styles?: ItemStylesProps;
    language_selector?: LanguageSelectorProps;
    social_media?: SocialMediaProps;
}

export interface ICollections {
    _id: string;
    display_name: string;
    internal_name: string;
    schedule_landing_message: string;
    menu_landing_message: string;
    menu_landing_message_2: string;
    logo_image: string;
    menu_links: Array<{ menu_id: IMenu }>;
}

export interface IMenu {
    _id: string;
    internal_name: string;
    display_name: string;
    header_text: string;
    header_image: string;
    footer_text: string;
    show_notations: boolean;
    section_expanded: boolean;
    show_logo: boolean;
    section_links: Array<{ section_id: ISection }>;
}

export interface ISection {
    _id: string;
    internal_name: string;
    display_name: string;
    header_text: string;
    section_image: string;
    section_always_open: boolean;
    item_links: Array<{
        map(arg0: (item: any) => void): unknown;
        item_id: IItem;
    }>;
}

export interface IItem {
    button_text: string;
    button_status: boolean;
    button_url: string;
    image_url: string;
    _id: string;
    type: string;
    internal_name: string;
    display_name: string;
    description: string;
    price_variations: Array<{
        price?: string;
        price_value?: number;
        leading_text?: string;
        trailing_text?: string;
    }>;
    price_type?: "standard" | "slash" | "modifier";
    notation_links?: Array<{
        notation_id: {
            _id: string;
            display_name: "Allium";
            type: "allergy";
        };
    }>;
    //notations: Array<{ display_name: string; type: string }>;
}
export interface IFullMenu {
    identifier: string;
    display_name: string;
    logo_image: string;
    currency_symbol: string;
    currency_placement: "before" | "after";
    // Restaurant page
    landing_message?: string;
    landing_button_text?: string;
    landing_button_link?: string;
    button_one_active: Boolean;
    button_one_position: String;
    landing_button_two_link: String;
    landing_button_two_text: String;
    button_two_active: Boolean;
    button_two_position: String;
    landing_button_three_link: String;
    landing_button_three_text: String;
    button_three_active: Boolean;
    button_three_position: String;
    social_media?: {
        facebook?: string;
        instagram?: string;
        twitter?: string;
        pinterest?: string;
        tiktok?: string;
        yelp?: string;
    };
    // End Restaurant page
    presenter_template: IPresentTemplate;
    web_template: IPresentTemplate;
    collection_links: Array<{
        collection_id: ICollections;
        days: (0 | 1 | 2 | 3 | 4 | 5 | 6)[];
        start: string;
        end: string;
    }>;
    use_schedules: boolean;
    ohwaiter_phone_number: string;
    available_languages: string[];
    source_language: string;
    default_collection?: string;
    timezone?: string;
    all_day_menu?: boolean;
    checkout_data?: {
        checkout?: boolean;
        guest_location?: {
            status?: string;
            skip_if_known?: boolean;
        };
        location_help_text?: null;
        guest_name?: {
            status?: string;
            skip_if_known?: boolean;
        };
        guest_member_id?: {
            status?: string;
            skip_if_known?: boolean;
            label?: string;
        };
        guest_phone?: {
            status?: string;
            skip_if_known?: boolean;
        };
    };
    instance: string;
}

export const REDIRECT_FLAG_NAME = "HAS_REDIRECT";

export const DEFAULT_TIMEZONE = "America/New_York";

export const STELLAR_MENUS_WEBSITE = "https://www.milescx.com/";

export const NOT_FOUND_PAGE_URI = "/not-found";

export const HEALTH_CHECK = "/health-check";

export const LISTING_PAGE_URI = "/listing";

export const COLLECTION_ID = "collection-id";
