export const countries = [
    { code: 93, name: "Afghanistan", isoCode: "AF" },
    { code: 355, name: "Albania", isoCode: "AL" },
    { code: 213, name: "Algeria", isoCode: "DZ" },
    { code: 376, name: "Andorra", isoCode: "AD" },
    { code: 244, name: "Angola", isoCode: "AO" },
    { code: 54, name: "Argentina", isoCode: "AR" },
    { code: 374, name: "Armenia", isoCode: "AM" },
    { code: 61, name: "Australia", isoCode: "AU" },
    { code: 43, name: "Austria", isoCode: "AT" },
    { code: 994, name: "Azerbaijan", isoCode: "AZ" },
    { code: 973, name: "Bahrain", isoCode: "BH" },
    { code: 880, name: "Bangladesh", isoCode: "BD" },
    { code: 375, name: "Belarus", isoCode: "BY" },
    { code: 32, name: "Belgium", isoCode: "BE" },
    { code: 501, name: "Belize", isoCode: "BZ" },
    { code: 229, name: "Benin", isoCode: "BJ" },
    { code: 975, name: "Bhutan", isoCode: "BT" },
    { code: 591, name: "Bolivia", isoCode: "BO" },
    { code: 387, name: "Bosnia and Herzegovina", isoCode: "BA" },
    { code: 267, name: "Botswana", isoCode: "BW" },
    { code: 55, name: "Brazil", isoCode: "BR" },
    { code: 673, name: "Brunei", isoCode: "BN" },
    { code: 359, name: "Bulgaria", isoCode: "BG" },
    { code: 226, name: "Burkina Faso", isoCode: "BF" },
    { code: 257, name: "Burundi", isoCode: "BI" },
    { code: 855, name: "Cambodia", isoCode: "KH" },
    { code: 237, name: "Cameroon", isoCode: "CM" },
    { code: 1, name: "Canada", isoCode: "CA" },
    { code: 238, name: "Cape Verde", isoCode: "CV" },
    { code: 236, name: "Central African Republic", isoCode: "CF" },
    { code: 235, name: "Chad", isoCode: "TD" },
    { code: 56, name: "Chile", isoCode: "CL" },
    { code: 86, name: "China", isoCode: "CN" },
    { code: 57, name: "Colombia", isoCode: "CO" },
    { code: 269, name: "Comoros", isoCode: "KM" },
    { code: 243, name: "Congo (Democratic Republic)", isoCode: "CD" },
    { code: 242, name: "Congo (Republic)", isoCode: "CG" },
    { code: 682, name: "Cook Islands", isoCode: "CK" },
    { code: 506, name: "Costa Rica", isoCode: "CR" },
    { code: 385, name: "Croatia", isoCode: "HR" },
    { code: 53, name: "Cuba", isoCode: "CU" },
    { code: 357, name: "Cyprus", isoCode: "CY" },
    { code: 420, name: "Czech Republic", isoCode: "CZ" },
    { code: 45, name: "Denmark", isoCode: "DK" },
    { code: 253, name: "Djibouti", isoCode: "DJ" },
    { code: 593, name: "Ecuador", isoCode: "EC" },
    { code: 20, name: "Egypt", isoCode: "EG" },
    { code: 503, name: "El Salvador", isoCode: "SV" },
    { code: 240, name: "Equatorial Guinea", isoCode: "GQ" },
    { code: 291, name: "Eritrea", isoCode: "ER" },
    { code: 372, name: "Estonia", isoCode: "EE" },
    { code: 268, name: "Eswatini", isoCode: "SZ" },
    { code: 251, name: "Ethiopia", isoCode: "ET" },
    { code: 679, name: "Fiji", isoCode: "FJ" },
    { code: 358, name: "Finland", isoCode: "FI" },
    { code: 33, name: "France", isoCode: "FR" },
    { code: 241, name: "Gabon", isoCode: "GA" },
    { code: 220, name: "Gambia", isoCode: "GM" },
    { code: 995, name: "Georgia", isoCode: "GE" },
    { code: 49, name: "Germany", isoCode: "DE" },
    { code: 233, name: "Ghana", isoCode: "GH" },
    { code: 30, name: "Greece", isoCode: "GR" },
    { code: 299, name: "Greenland", isoCode: "GL" },
    { code: 502, name: "Guatemala", isoCode: "GT" },
    { code: 224, name: "Guinea", isoCode: "GN" },
    { code: 245, name: "Guinea-Bissau", isoCode: "GW" },
    { code: 592, name: "Guyana", isoCode: "GY" },
    { code: 509, name: "Haiti", isoCode: "HT" },
    { code: 504, name: "Honduras", isoCode: "HN" },
    { code: 852, name: "Hong Kong", isoCode: "HK" },
    { code: 36, name: "Hungary", isoCode: "HU" },
    { code: 354, name: "Iceland", isoCode: "IS" },
    { code: 91, name: "India", isoCode: "IN" },
    { code: 62, name: "Indonesia", isoCode: "ID" },
    { code: 98, name: "Iran", isoCode: "IR" },
    { code: 964, name: "Iraq", isoCode: "IQ" },
    { code: 353, name: "Ireland", isoCode: "IE" },
    { code: 972, name: "Israel", isoCode: "IL" },
    { code: 39, name: "Italy", isoCode: "IT" },
    { code: 225, name: "Ivory Coast", isoCode: "CI" },
    { code: 81, name: "Japan", isoCode: "JP" },
    { code: 962, name: "Jordan", isoCode: "JO" },
    { code: 7, name: "Kazakhstan", isoCode: "KZ" },
    { code: 254, name: "Kenya", isoCode: "KE" },
    { code: 686, name: "Kiribati", isoCode: "KI" },
    { code: 383, name: "Kosovo", isoCode: "XK" },
    { code: 965, name: "Kuwait", isoCode: "KW" },
    { code: 996, name: "Kyrgyzstan", isoCode: "KG" },
    { code: 856, name: "Laos", isoCode: "LA" },
    { code: 371, name: "Latvia", isoCode: "LV" },
    { code: 961, name: "Lebanon", isoCode: "LB" },
    { code: 266, name: "Lesotho", isoCode: "LS" },
    { code: 231, name: "Liberia", isoCode: "LR" },
    { code: 218, name: "Libya", isoCode: "LY" },
    { code: 423, name: "Liechtenstein", isoCode: "LI" },
    { code: 370, name: "Lithuania", isoCode: "LT" },
    { code: 352, name: "Luxembourg", isoCode: "LU" },
    { code: 853, name: "Macau", isoCode: "MO" },
    { code: 389, name: "North Macedonia", isoCode: "MK" },
    { code: 261, name: "Madagascar", isoCode: "MG" },
    { code: 265, name: "Malawi", isoCode: "MW" },
    { code: 60, name: "Malaysia", isoCode: "MY" },
    { code: 960, name: "Maldives", isoCode: "MV" },
    { code: 223, name: "Mali", isoCode: "ML" },
    { code: 356, name: "Malta", isoCode: "MT" },
    { code: 692, name: "Marshall Islands", isoCode: "MH" },
    { code: 222, name: "Mauritania", isoCode: "MR" },
    { code: 230, name: "Mauritius", isoCode: "MU" },
    { code: 52, name: "Mexico", isoCode: "MX" },
    { code: 691, name: "Micronesia", isoCode: "FM" },
    { code: 373, name: "Moldova", isoCode: "MD" },
    { code: 377, name: "Monaco", isoCode: "MC" },
    { code: 976, name: "Mongolia", isoCode: "MN" },
    { code: 382, name: "Montenegro", isoCode: "ME" },
    { code: 212, name: "Morocco", isoCode: "MA" },
    { code: 258, name: "Mozambique", isoCode: "MZ" },
    { code: 95, name: "Myanmar", isoCode: "MM" },
    { code: 264, name: "Namibia", isoCode: "NA" },
    { code: 674, name: "Nauru", isoCode: "NR" },
    { code: 977, name: "Nepal", isoCode: "NP" },
    { code: 31, name: "Netherlands", isoCode: "NL" },
    { code: 687, name: "New Caledonia", isoCode: "NC" },
    { code: 64, name: "New Zealand", isoCode: "NZ" },
    { code: 505, name: "Nicaragua", isoCode: "NI" },
    { code: 227, name: "Niger", isoCode: "NE" },
    { code: 234, name: "Nigeria", isoCode: "NG" },
    { code: 683, name: "Niue", isoCode: "NU" },
    { code: 850, name: "North Korea", isoCode: "KP" },
    { code: 47, name: "Norway", isoCode: "NO" },
    { code: 968, name: "Oman", isoCode: "OM" },
    { code: 92, name: "Pakistan", isoCode: "PK" },
    { code: 680, name: "Palau", isoCode: "PW" },
    { code: 970, name: "Palestine", isoCode: "PS" },
    { code: 507, name: "Panama", isoCode: "PA" },
    { code: 675, name: "Papua New Guinea", isoCode: "PG" },
    { code: 595, name: "Paraguay", isoCode: "PY" },
    { code: 51, name: "Peru", isoCode: "PE" },
    { code: 63, name: "Philippines", isoCode: "PH" },
    { code: 48, name: "Poland", isoCode: "PL" },
    { code: 351, name: "Portugal", isoCode: "PT" },
    { code: 974, name: "Qatar", isoCode: "QA" },
    { code: 40, name: "Romania", isoCode: "RO" },
    { code: 7, name: "Russia", isoCode: "RU" },
    { code: 250, name: "Rwanda", isoCode: "RW" },
    { code: 290, name: "Saint Helena", isoCode: "SH" },
    { code: 1869, name: "Saint Kitts and Nevis", isoCode: "KN" },
    { code: 1758, name: "Saint Lucia", isoCode: "LC" },
    { code: 508, name: "Saint Pierre and Miquelon", isoCode: "PM" },
    { code: 1784, name: "Saint Vincent and the Grenadines", isoCode: "VC" },
    { code: 685, name: "Samoa", isoCode: "WS" },
    { code: 378, name: "San Marino", isoCode: "SM" },
    { code: 239, name: "Sao Tome and Principe", isoCode: "ST" },
    { code: 966, name: "Saudi Arabia", isoCode: "SA" },
    { code: 221, name: "Senegal", isoCode: "SN" },
    { code: 381, name: "Serbia", isoCode: "RS" },
    { code: 248, name: "Seychelles", isoCode: "SC" },
    { code: 232, name: "Sierra Leone", isoCode: "SL" },
    { code: 65, name: "Singapore", isoCode: "SG" },
    { code: 421, name: "Slovakia", isoCode: "SK" },
    { code: 386, name: "Slovenia", isoCode: "SI" },
    { code: 677, name: "Solomon Islands", isoCode: "SB" },
    { code: 252, name: "Somalia", isoCode: "SO" },
    { code: 27, name: "South Africa", isoCode: "ZA" },
    { code: 82, name: "South Korea", isoCode: "KR" },
    { code: 211, name: "South Sudan", isoCode: "SS" },
    { code: 34, name: "Spain", isoCode: "ES" },
    { code: 94, name: "Sri Lanka", isoCode: "LK" },
    { code: 249, name: "Sudan", isoCode: "SD" },
    { code: 597, name: "Suriname", isoCode: "SR" },
    { code: 268, name: "Eswatini", isoCode: "SZ" },
    { code: 46, name: "Sweden", isoCode: "SE" },
    { code: 41, name: "Switzerland", isoCode: "CH" },
    { code: 963, name: "Syria", isoCode: "SY" },
    { code: 886, name: "Taiwan", isoCode: "TW" },
    { code: 992, name: "Tajikistan", isoCode: "TJ" },
    { code: 255, name: "Tanzania", isoCode: "TZ" },
    { code: 66, name: "Thailand", isoCode: "TH" },
    { code: 670, name: "Timor-Leste", isoCode: "TL" },
    { code: 228, name: "Togo", isoCode: "TG" },
    { code: 690, name: "Tokelau", isoCode: "TK" },
    { code: 676, name: "Tonga", isoCode: "TO" },
    { code: 216, name: "Tunisia", isoCode: "TN" },
    { code: 90, name: "Turkey", isoCode: "TR" },
    { code: 993, name: "Turkmenistan", isoCode: "TM" },
    { code: 688, name: "Tuvalu", isoCode: "TV" },
    { code: 256, name: "Uganda", isoCode: "UG" },
    { code: 380, name: "Ukraine", isoCode: "UA" },
    { code: 971, name: "United Arab Emirates", isoCode: "AE" },
    { code: 44, name: "United Kingdom", isoCode: "GB" },
    { code: 1, name: "United States", isoCode: "US" },
    { code: 598, name: "Uruguay", isoCode: "UY" },
    { code: 998, name: "Uzbekistan", isoCode: "UZ" },
    { code: 678, name: "Vanuatu", isoCode: "VU" },
    { code: 379, name: "Vatican City", isoCode: "VA" },
    { code: 58, name: "Venezuela", isoCode: "VE" },
    { code: 84, name: "Vietnam", isoCode: "VN" },
    { code: 681, name: "Wallis and Futuna", isoCode: "WF" },
    { code: 967, name: "Yemen", isoCode: "YE" },
    { code: 260, name: "Zambia", isoCode: "ZM" },
    { code: 263, name: "Zimbabwe", isoCode: "ZW" }
];
